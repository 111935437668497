export class Constants {
  constructor() {}
  static AVOID_INTERCEPTOR_HEADER = 'X-Skip-Interceptor-Error-Handler';
  static CONTENT_TYPE_HEADER = 'Content-Type';

  static accessToken = 'accessToken';
  static userKey = 'user';

  static EMAIL = 'email';
  /* routes  names */
  static LOGIN_ROUTE = 'login';
  static LOGOUT_ROUTE = 'sign-out';
  static REGISTER_ROUTE = 'register';
  static ACTIVATE_ACCOUNT_ROUTE = 'activate-account';
  static GUEST_REGISTER_ROUTE = 'guest-register';
  static FORGOT_ROUTE = 'forgot-password';
  static RESET_PASSWORD_ROUTE = 'reset-password';
  static SEARCH_ROUTE = 'search';
  static HOME_ROUTE = 'home';
  static NOT_FOUND_ROUTE = 'not-found';

  /** helpers */
  static DATE_FORMAT_FOR_BACK_SENDER = 'yyyy-MM-dd';
  static MY_DATE_FORMAT = {
    parse: {
      dateInput: 'DD-MM-YYYY', // this is how your date will be parsed from Input
    },
    display: {
      dateInput: 'DD-MM-YYYY', // this is how your date will get displayed on the Input
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

  /** http headers names */
  static RESPONSE_HEADER_TOTAL_ITEMS = 'X-Total-Count';
}
